* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  /* margin-left: 30px; */
}

.footer-col h4 {
  text-align: start;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 35px 0 35px 30px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -10px;
  background-color: #e91e63;
  height: 2px;
  box-sizing: border-box;
  width: 55px;
}

.footer-col li {
  margin-bottom: 10px;
}

.footer-col li a {
  font-size: 16px;
  text-transform: capitalize;
  /* color: #ffffff; */
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul > li > a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  /* color: #24262b; */
  background-color: crimson;
}
