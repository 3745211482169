.text {
  color: #b71540;
  padding: 5px 8px;
  position: relative;
  bottom: 30px;
  width: 100%;

  font-size: 45px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  /* opacity: "0.7"; */
  display: inline;
  font-family: "Playfair Display", serif;
}

.carousel-inner {
  object-fit: contain;
}
