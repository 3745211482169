@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@800&family=Poppins:wght@300;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.navbar-nav a {
  font-weight: lighter;
}
.navbar-nav a {
  color: #dab551;
}

.navbar-nav a:hover::after {
  content: " ";
  padding-left: 8px;
  border-right: 1px solid #dab551;
  box-sizing: border-box;
}
.card-title {
  border-bottom: 3px solid crimson;
  display: inline-block;
  padding-bottom: 4px;
}

.effect {
  transition: 1s;
}
.effect:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  z-index: 2;
}
.colour {
  color: white;
  background-color: #2f3640;
  padding: 6px;
  /* font-size: 30px; */
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 7px;
}
.colour:hover {
  background-color: #081439;
}

.title {
  color: #081439;
  font-family: "Playfair Display", serif;
  font-size: 40px;
  transition: ease-in-out 1s;
  padding-bottom: 5px;
  margin: 50px 0;
}
.title:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.th {
  background-color: #2f3640;
  color: aliceblue;
}
.th:hover {
  background-color: #081439;
}
.tm {
  color: #081439;
}
.copy {
  text-align: center;
  font-size: x-small;
}
.under {
  border-bottom: 5px solid crimson;
  padding-bottom: 5px;
}

p,
ul {
  text-align: justify;
}

.input-field {
  padding: 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid gray;
  margin-top: 10px;
  width: 70%;
}
.input-button {
  background-color: rgb(8, 20, 57, 0.95);
  border: none;
  border-radius: 5px;
  padding: 10px 25px;
  color: white;
  width: 50%;
  margin-top: 20px;
}
.input-button:hover {
  background-color: #081439;
}
.bg-image {
  /* The image used */
  background-image: url(/static/media/contact.0a7e23bc.png);

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact {
  font-family: "Playfair Display", serif;
  color: #081439;
}
.shadow {
  box-shadow: 0 0 20px 5px rgba(156, 155, 155, 0.849);
}

.text {
  color: #b71540;
  padding: 5px 8px;
  position: relative;
  bottom: 30px;
  width: 100%;

  font-size: 45px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  /* opacity: "0.7"; */
  display: inline;
  font-family: "Playfair Display", serif;
}

.carousel-inner {
  object-fit: contain;
}

.gallery {
  color: #081439;
  font-family: "Playfair Display", serif;
  font-size: 50px;
  padding-right: 10px;
}
.col {
  transition: 1s;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
.col:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  z-index: 2;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  /* margin-left: 30px; */
}

.footer-col h4 {
  text-align: start;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 35px 0 35px 30px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -10px;
  background-color: #e91e63;
  height: 2px;
  box-sizing: border-box;
  width: 55px;
}

.footer-col li {
  margin-bottom: 10px;
}

.footer-col li a {
  font-size: 16px;
  text-transform: capitalize;
  /* color: #ffffff; */
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul > li > a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  /* color: #24262b; */
  background-color: crimson;
}

